import React, { Component }  from 'react';


class Footer extends Component {
    render() {
        return(
            <div className="Footer">
                <h1>Some text at the bottom</h1>
            </div>
        );
    }
}

export default Footer;