//this is the placeholder for images are going
export const MAIN = [
    {
        id: 0,
        text: "Here is some basic text",
        image: "/imageFolder/image.png"
    },

    {
        id: 1,
        text: "This is the 2nd page",
        image: "/imageFolder/image.png"
    }
]