export const CLEANERS = [
    {
        id: 0,
        name: "John Doe",
        email: "jwlyons@gmail.com",
        phone: 847764911
    },
    {
        id: 1,
        name: "Mary Jane",
        email: "running@123.com",
        phone: 123467890
    }
];