import React, { Component } from 'react';

class TopModal extends Component {
    render() {
        return (
            <React.Fragment>
                <div class="col-sm-9 img-fluid Centered">
                <img src='images/Carraige_Mail.png' class="img-fluid Centered Padding" alt="Logo" />

                </div>
            </React.Fragment>

        );
    }
}

export default TopModal;